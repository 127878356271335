import React, { useState } from 'react'
import { Link } from 'gatsby'
import SubNavbar from '../../components/Menu'
import '../../styles/homehero.sass'

const Navbar = ({ showSub, showMenu }) => {
  const [active, setActive] = useState(true)
  const [navBarActiveClass, setNavBarActiveClass] = useState('')

  const toggleHamburger = () => {
    setActive(!active)
    setNavBarActiveClass(active ? 'is-active' : '')
  }

  return (
    <nav
      className="navbar is-transparent"
      role="navigation"
      aria-label="main-navigation"
    >
      <div className="container">
        <div className="brand">
          <div className="flex-container">
            <div className="hidden-desktop" style={{ width: '3.25rem' }}></div>
            <Link to="/" className="navbar-title" title="Logo">
              Phoebe Wynne
            </Link>
            <div
              className={`navbar-burger burger ${navBarActiveClass}`}
              data-target="navMenu"
              style={{ margin: 'unset' }}
              onClick={() => toggleHamburger()}
            >
              <span />
              <span />
              <span />
            </div>
          </div>

          {showSub && (
            <h1 className="navbar-header">
              Classicist, Feminist, Educator, Author.
            </h1>
          )}
          {showMenu && <SubNavbar />}
          <Link to="/about">
            <button className="welcome-button">Welcome</button>
          </Link>
        </div>

        <div id="navMenu" className={`navbar-menu ${navBarActiveClass}`}>
          <div className="navbar-start has-text-centered">
            <Link className="navbar-item" to="/about">
              About
            </Link>
            <Link className="navbar-item" to="/books">
              Books
            </Link>
            <Link className="navbar-item" to="/press">
              Press
            </Link>
            <Link className="navbar-item" to="/contact">
              Contact
            </Link>
          </div>
        </div>
      </div>
    </nav>
  )
}

export default Navbar
